<template>
    <div class="model-list-view">
        <div class="ctrl-bar">
            <div class="label">创建人：</div>
            <n-input
                v-model:value="creator"
                size="small"
                style="width: 200px"
                class="input"
                placeholder="输入创建人"
                clearable
                @keyup="onKeyUp"
            >
            </n-input>
            <n-button
                class="input"
                size="small"
                quaternary
                @click="setCurrentUser"
            >
                只看自己创建
            </n-button>
            <n-button
                style="margin-bottom: 4px"
                type="primary"
                size="small"
                @click="loadData"
            >
                <template #icon>
                    <n-icon>
                        <SearchFilled />
                    </n-icon>
                </template>
                查询
            </n-button>
            <n-button
                style="margin-left: auto; margin-bottom: 4px"
                size="small"
                type="primary"
                ghost
                @click="createAnalysisModel"
            >
                新建模型
            </n-button>
        </div>
        <div class="content">
            <n-data-table
                style="background-color: #fff"
                :columns="tableColumns"
                :data="tableData"
                :loading="loading"
            ></n-data-table>
            <div class="pagination">
                <n-pagination
                    v-model:page="index"
                    :page-count="pageCount"
                    @update:page="onPageIndexChange"
                ></n-pagination>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';
.model-list-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 50px;

        .pagination {
            padding-top: 10px;
            display: flex;
            justify-content: right;
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/global';

import {
    NButton,
    NInput,
    NIcon,
    NDataTable,
    NPagination,
    useMessage,
} from 'naive-ui';

import { SearchFilled, WestFilled } from '@vicons/material';

import { CostNervAPI } from '@/common/API';

import CtrlCell from '@/components/CtrlCell';

const userStore = useUserStore();

const router = useRouter();

const route = useRoute();

const message = useMessage();

let tableColumns = ref([
    {
        title: 'ID',
        key: 'id',
        width: 80,
        align: 'center',
    },
    {
        title: '名称',
        key: 'name',
        ellipsis: true,
        resizable: true,
        render(row) {
            return (
                <NButton text onClick={() => viewModel(row)}>
                    {row.name}
                </NButton>
            );
        },
    },
    {
        title: '创建人',
        key: 'creator',
        width: 150,
        align: 'center',
    },
    {
        title: '操作',
        width: 80,
        align: 'center',
        render(row) {
            return (
                <CtrlCell
                    row={{ row }}
                    onEdit={() => editAnalysisModel(row)}
                    onDelete={() => deleteAnalysisModel(row.id)}
                ></CtrlCell>
            );
        },
    },
]);

let tableData = ref([]);

let creator = ref('');

let index = ref(+route.query.index || 1);

let total = ref(0);

let size = ref(10);

let pageCount = computed(
    () =>
        Math.floor(total.value / size.value) +
        (!!(total.value % size.value) ? 1 : 0)
);

let loading = ref(false);

function loadData() {
    loading.value = true;
    CostNervAPI.getModelList(index.value, size.value, creator.value).then(
        res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            tableData.value = res.data.list;
            total.value = res.data.total;
        }
    );
}

loadData();

function onPageIndexChange(i) {
    router.replace({ query: { index: i } });
    loadData();
}

function viewModel(row) {
    router.push(`/cost_nerv/model/${row.id}`);
}

function editAnalysisModel(row) {
    router.push(`/cost_nerv/model/${row.id}/editor`);
}

function createAnalysisModel() {
    message.loading('创建中...', { duration: 0 });
    CostNervAPI.createModel({
        name: '未命名模型_' + new Date().getTime(),
        creator: userStore.currentUser.username,
    })
        .then(res => {
            message.destroyAll();
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            router.push(`/cost_nerv/model/${res.data}/editor`);
        })
        .catch(err => {
            console.log(err);
            message.error('未知异常导致加载失败');
        });
}

function deleteAnalysisModel(id) {
    message.loading('操作中...', { duration: 0 });
    CostNervAPI.deleteModel(id).then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        loadData();
    });
}

function setCurrentUser() {
    creator.value = userStore.currentUser.username;
    loadData();
}

function onKeyUp(e) {
    if (e.code === 'Enter') {
        loadData();
    }
}
</script>
